<template>
    <main>
        <page-header-compact>Configuración - Grupo Antecedentes</page-header-compact>
        <div class="container-xl px-4 mt-n10">
            <div class="card">
                <div class="card-body">
                    <div class="col-12 d-flex justify-content-end py-4"> 
                        <button @click="modificar=false; abrirModal();" type="button" class="btn btn-primary ">
                            Crear Grupo&nbsp;<i class="fas fa-plus"></i>
                        </button>
                    </div>
                    <div class="modal fade" id="modalGrupo" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{{tituloModal}}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form >
                                        <div class="form-group">
                                            <label class="form-label">Codigo</label>
                                            <input type="text" class="form-control" v-model="codigo" placeholder="Codigo" :class="{'is-invalid':$v.codigo.$error && $v.codigo.$dirty}">
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Nombre</label>
                                            <input type="text" class="form-control" v-model="nombre" placeholder="Nombre" :class="{'is-invalid':$v.nombre.$error && $v.nombre.$dirty}">
                                        </div>
                                    </form>
                                </div>
                                <div class="modal-footer">
                                    <button class="btn btn-success" @click="guardar()">
                                        <i class="fas fa-save"></i>&nbsp;Guardar
                                     </button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table table-responsive">
                        <table class="table table-striped">
                            <tr class="table-primary">
                                <th>Codigo</th>
                                <th>Nombre</th>
                                <th>Opciones</th>
                            </tr>
                            <tr v-for="item in grupos" :key="item.id">
                                <td>{{item.codigo}}</td>
                                <td>{{item.nombre}}</td>
                                <td>
                                   <div class="form-row">
                                        <div class="form-group px-2">
                                            <button @click="modificar=true; abrirModal(item);" class="btn btn-outline-primary lift" >
                                                <i class="fa fa-edit"></i>&nbsp;Editar
                                            </button>
                                        </div>
                                        <div class="form-group px-2">
                                            <button @click="eliminarGrupo(item.id);" class="btn btn-outline-danger lift" >
                                                <i class="fa fa-trash-alt"></i>&nbsp;Eliminar
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                        
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import PageHeaderCompact from "../../../../../components/layouts/content/page-header-compact";
import grupoAntecedenteService from '../../../../../services/grupoAntecedenteService';

import 'bootstrap';
import $ from 'jquery';
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";



export default {
    components: {PageHeaderCompact},
    data(){
        return{
            id:0,
            modificar:true,
            codigo:'',
            nombre:'',
            tituloModal:'',
            grupos:{},
        }
    },
    validations (){
        return {
            codigo:{required},
            nombre:{required},
        }
    },
    methods: {
        abrirModal(grupo={}){
            $('#modalGrupo').modal('show');
            if(this.modificar){
                this.id=grupo.id;
                this.tituloModal="Modificar Grupo";
                this.codigo=grupo.codigo;
                this.nombre=grupo.nombre;
            }else{
                this.id=0;
                this.tituloModal="Crear Grupo";
                this.codigo='';
                this.nombre='';
            }
        },
        async cargarGrupos(){
            const response = await grupoAntecedenteService.index();
            this.grupos=response.data;
        },
        async guardar(){
            this.$v.$touch();
            if(this.$v.$invalid){
                return 
            }
            let grupo={
                id: this.id,
                codigo:this.codigo,
                nombre:this.nombre,
            }
            if(this.modificar){
                this.LoaderSpinnerShow();
                await grupoAntecedenteService.update(grupo);
                this.LoaderSpinnerHide();
                Swal.fire('Datos actualizados con exito','', 'success');
                $('#modalGrupo').modal('hide');
                this.cargarGrupos();
                
            }else{
                this.LoaderSpinnerShow();
                await grupoAntecedenteService.store(grupo);
                this.LoaderSpinnerHide();
                Swal.fire('Datos guardados con exito','', 'success');
                $('#modalGrupo').modal('hide');
                this.cargarGrupos();
            }
            
        },
        async eliminarGrupo(id){
            this.LoaderSpinnerShow();
            await grupoAntecedenteService.delete(id);
            this.LoaderSpinnerHide();
            Swal.fire('Datos eliminados con exito','', 'success');
            $('#modalGrupo').modal('hide');
            this.cargarGrupos();
        }
    },
    created(){
        this.cargarGrupos();
    }
}
</script>

